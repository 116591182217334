<template>
	<div class="container" v-if="computedValue === `ko`">
		<div
			class="service-intro"
			:style="{ backgroundImage: `url(${this.servicemain})` }"
		>
			<div class="service-sub-description">
				<h1>Continuous research<br />Advanced technology</h1>
				<h3 class="title_left_animation">
					<div>우리는 늘 탐구하고, 그에 따라 기술도 발전한다</div>
				</h3>
			</div>
		</div>
		<div class="servicetitle">
			<h1>F C T S</h1>
			<p class="subtitle_1">
				<span>F</span>our <span>C</span>hains <span>T</span>echnology
				<span>S</span>ervice
			</p>
			<p class="subtitle_2">
				Cryptography, A.I, Blockchains, Security 4개의 기술을 연결하여<br />
				Blockchain 기반 데이터 보안 플랫폼을 제공합니다.
			</p>
		</div>
		<div class="service_contentbox">
			<div class="box_border"></div>
			<h2>모두의 미래에 필요한 기술</h2>
			<p>
				인공지능을 접목한 데이터 보안 플랫폼을 개발하여<br />
				기업에 사용되는 비공개 데이터를 빠르고 보다 안전하게<br />
				운용 가능한 서비스를 제공하고 있습니다
			</p>
		</div>
		<div class="service-detail">
			<p>DISCRIMINATION SERVICE</p>
			<h2>클라이언트를 위한 FCTS의 차별화된 서비스</h2>
			<h3>업무환경의 질과 효율성을 높여보세요!</h3>
			<div class="serviceImg-box">
				<div class="serviceImg-box--sub">
					<div class="sub--images1">
						<div class="cricle">
							<img :src="developer" />
						</div>
						<div class="border-line"></div>
						<div class="service-textbox">
							<h1>01.</h1>
							<h2>개발자 가이드</h2>
							<p>개발 시작 단계부터 연동 방법까지 상세하게 안내합니다.</p>
						</div>
					</div>
					<div class="sub--images2">
						<div class="cricle">
							<img :src="diagnosis" />
						</div>
						<div class="border-line"></div>
						<div class="service-textbox">
							<h1>02.</h1>
							<h2>진단 페이지</h2>
							<p>기업 환경에 따른 맞춤형 플랫폼을 추천합니다.</p>
						</div>
					</div>
					<div class="sub--images3">
						<div class="cricle">
							<img :src="product" />
						</div>
						<div class="border-line"></div>
						<div class="service-textbox">
							<h1>03.</h1>
							<h2>제품 서비스</h2>
							<p>
								새롭게 개발한 암호, 보안, 백신의 차별화된 서비스를 선보입니다.
							</p>
						</div>
					</div>
					<div class="sub--images4">
						<div class="cricle">
							<img :src="solution" />
						</div>
						<div class="border-line"></div>
						<div class="service-textbox">
							<h1>04.</h1>
							<h2>솔루션</h2>
							<p>
								FCTS의 연구를 통해 각종 서비스에 적용하는 기술을 확인 및
								안내합니다.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="service-detail">
			<div class="service-subtitle service-subtitle--2">
				<span>REPRESENTATIVE SERVICE</span>
				<h2>다양한 업무환경을 위한 맞춤 서비스</h2>
			</div>

			<div class="service-subservice service-subservice--reverse">
				<div class="bottom-stroke">
					<div class="subservice-img">
						<img v-scrollanimation :src="service1" />
						<div class="bg_color">
							<router-link to="/servicecrypto">
								<button class="btn--md btn--border-gray">자세히 보기</button>
							</router-link>
						</div>
					</div>
					<div class="subservice-text">
						<div>
							<h2>Cryptographic Schema</h2>
							<span>암호 스키마</span>
						</div>
						<div class="border_box">
							<p>
								FCTS는 원천기술 개발을 통하여 암호 스키마 서비스를 제공합니다.
								동형 암호 구조 (Homomorphic Encryption Structure)를 가지는 암호
								스키마로서 동형 분석과 데이터 압축 등에 다양한 분야에서 활용
								가능한 서비스입니다.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="service-subservice">
				<div class="bottom-stroke">
					<div class="subservice-img subservice2">
						<img v-scrollanimation :src="service2" />
						<div class="bg_color">
							<router-link to="/servicesecurity">
								<button class="btn--md btn--border-gray">자세히 보기</button>
							</router-link>
						</div>
					</div>
					<div class="subservice-text">
						<h2>Modulus</h2>
						<span>데이터 분석 프레임워크</span>
						<div class="border_box">
							<p>
								실시간 데이터 가시화 서비스를 제공하는 모듈러스는 브라우저 형
								데이터 분석 프레임워크로 어디서나 접속하여 사용 가능하며
								데이터를 직접 보면서 접속한 사용자들 간의 실시간 코드 공유를
								통한 협업이 가능합니다. 소스코드의 모듈화와 프로젝트의 자 동
								연동, 동형 분석 서비스까지 탑재한 모듈러스로 데이터 분석의 모든
								과정을 편리하게 이용할 수 있습니다.
							</p>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="service-subservice">
				<div class="bottom-stroke">
					<div class="subservice-img subservice2">
						<img v-scrollanimation :src="service2" />
						<div class="bg_color">
							<button class="btn--md btn--border-gray">
								<router-link to="/serviceBiMining">자세히 보기</router-link>
							</button>
						</div>
					</div>
					<div class="subservice-text">
						<h2>BI-Mining</h2>
						<span>데이터 시각화 기반 업무용 툴</span>
						<div class="border_box">
							<p>
								실시간 데이터 가시화 서비스를 제공하는 모듈러스는 브라우저 형
								데이터 분석 프레임워크로 어디서나 접속하여 사용 가능하며
								데이터를 직접 보면서 접속한 사용자들 간의 실시간 코드 공유를
								통한 협업이 가능합니다. 소스코드의 모듈화와 프로젝트의 자 동
								연동, 동형 분석 서비스까지 탑재한 모듈러스로 데이터 분석의 모든
								과정을 편리하게 이용할 수 있습니다.
							</p>
						</div>
					</div>
				</div>
			</div> -->
			<div class="service-subservice service-subservice--reverse">
				<div class="bottom-stroke">
					<div class="subservice-img">
						<img v-scrollanimation :src="service3" />
						<div class="bg_color">
							<router-link to="/servicevaccine">
								<button class="btn--md btn--border-gray">자세히 보기</button>
							</router-link>
						</div>
					</div>
					<div class="subservice-text">
						<h2>백신 FC-Anti Virus</h2>
						<span>백신 FC-Anti Virus</span>
						<div class="border_box">
							<p>
								웜(Worm), 트로이목마(Trojan), 랜섬웨어(Ransomware) 등 다양한
								유형의 악성코드를 탐지 및 치료하는 백신 서비스입니다. FCTS는
								Machine Learning 기법 활용과 독자적으로 개발한 유사도 알고리즘을
								통해 기존에 알려진 악성코드뿐만 아니라 신규 또는 변종 악성코드를
								탐지하고 치료하는 백신 서비스를 제공합니다.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="service-subservice service-subservice--reverse">
				<div class="bottom-stroke">
					<div class="subservice-img">
						<img v-scrollanimation :src="service4" />
						<div class="bg_color">
							<router-link to="/serviceBiMining">
								<button class="btn--md btn--border-gray">자세히 보기</button>
							</router-link>
						</div>
					</div>
					<div class="subservice-text">
						<h2>BI Mining</h2>
						<span>업무 보조 툴</span>
						<div class="border_box">
							<p>
								BI Mining은 데이터 시각화, 자동 차트 제작, 보고서 작성, PPT
								템플릿 제공 등 다양한 기능을 갖춘 종합 업무 보조 툴입니다.
								사용자는 복잡한 작업을 간편하게 처리할 수 있으며, 엑셀 수정부터
								PPT 제작까지 하나의 플랫폼에서 효율적으로 수행할 수 있습니다.
								클라우드 기반으로 언제 어디서나 쉽게 접근해 업무를 자동화할 수
								있는 서비스입니다.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="service-subservice service-subservice--reverse">
				<div class="bottom-stroke">
					<div class="subservice-img">
						<img v-scrollanimation :src="service5" />
						<div class="bg_color">
							<router-link to="/serviceCryptaLedger">
								<button class="btn--md btn--border-gray">자세히 보기</button>
							</router-link>
						</div>
					</div>
					<div class="subservice-text">
						<h2>CryptaLedger Tech</h2>
						<span>분산원장 암호화 저장 기술</span>
						<div class="border_box">
							<p>
								CryptaLedger Tech은 동형암호화 기술을 통해 거래 데이터를
								암호화된 상태에서 분산원장에 안전하게 저장하고 전송할 수 있는
								혁신적인 솔루션을 제공합니다. 이 기술은 계정 정보와 트랜잭션
								정보를 철저하게 보호하며, 데이터의 기밀성과 안전성을 보장합니다.
								복호화 과정 없이도 사용자 데이터를 안전하게 관리할 수 있으며,
								Public Blockchain에서도 강력한 보안을 유지할 수 있습니다.
								CryptaLedger Tech은 사용자가 안전하게 거래 데이터를 처리하고
								관리할 수 있는 환경을 제공하는 서비스입니다.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container" v-else-if="computedValue === `en`">
		<div
			class="service-intro"
			:style="{ backgroundImage: `url(${this.servicemain})` }"
		>
			<div class="service-sub-description">
				<h1>Continuous research<br />Advanced technology</h1>
			</div>
		</div>
		<div class="servicetitle">
			<h1>F C T S</h1>
			<p class="subtitle_1">
				<span>F</span>our <span>C</span>hains <span>T</span>echnology
				<span>S</span>ervice
			</p>
			<p class="subtitle_2">
				Connecting four technologies—Cryptography, A.I., Blockchain,<br />
				and Security—we provide a Blockchain-based data security platform.
			</p>
		</div>
		<div class="service_contentbox">
			<div class="box_border"></div>
			<h2>Technology Needed for Everyone’s Future</h2>
			<p>
				We develop a data security platform powered by artificial intelligence,
				<br />
				providing companies with a service that enables fast <br />and secure
				management of confidential data.
			</p>
		</div>
		<div class="service-detail">
			<p>DISCRIMINATION SERVICE</p>
			<h2>FCTS's Customized Service for Clients</h2>
			<h3>Enhance the quality and efficiency of your work environment!</h3>
			<div class="serviceImg-box">
				<div class="serviceImg-box--sub">
					<div class="sub--images1">
						<div class="cricle">
							<img :src="developer" />
						</div>
						<div class="border-line"></div>
						<div class="service-textbox">
							<h1>01.</h1>
							<h2>Developer Guide</h2>
							<p>
								Detailed guidance from the start of development to the
								integration stage.
							</p>
						</div>
					</div>
					<div class="sub--images2">
						<div class="cricle">
							<img :src="diagnosis" />
						</div>
						<div class="border-line"></div>
						<div class="service-textbox">
							<h1>02.</h1>
							<h2>Diagnosis Page</h2>
							<p>
								Recommends a platform tailored to the company’s environment.
							</p>
						</div>
					</div>
					<div class="sub--images3">
						<div class="cricle">
							<img :src="product" />
						</div>
						<div class="border-line"></div>
						<div class="service-textbox">
							<h1>03.</h1>
							<h2>Product Service</h2>
							<p>
								Provides specialized encryption, security, and antivirus
								services developed by FCTS.
							</p>
						</div>
					</div>
					<div class="sub--images4">
						<div class="cricle">
							<img :src="solution" />
						</div>
						<div class="border-line"></div>
						<div class="service-textbox">
							<h1>04.</h1>
							<h2>Solution</h2>
							<p>
								Confirms and guides the application of FCTS’s research
								technologies to various services.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="service-detail">
			<div class="service-subtitle service-subtitle--2">
				<span>REPRESENTATIVE SERVICE</span>
				<h2>FCTS's Customized Service for Clients</h2>
			</div>

			<div class="service-subservice service-subservice--reverse">
				<div class="bottom-stroke">
					<div class="subservice-img">
						<img v-scrollanimation :src="service1" />
						<div class="bg_color">
							<router-link to="/servicecrypto">
								<button class="btn--md btn--border-gray">View Details</button>
							</router-link>
						</div>
					</div>
					<div class="subservice-text">
						<h2>Cryptographic Schema</h2>
						<div class="border_box">
							<p>
								FCTS provides a crypto schema service through the development of
								core technologies. As a crypto schema with a Homomorphic
								Encryption Structure, it is a versatile service that can be
								utilized in various fields such as homomorphic analysis and data
								compression.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="service-subservice">
				<div class="bottom-stroke">
					<div class="subservice-img subservice2">
						<img v-scrollanimation :src="service2" />
						<div class="bg_color">
							<router-link to="/servicesecurity">
								<button class="btn--md btn--border-gray">View Details</button>
							</router-link>
						</div>
					</div>
					<div class="subservice-text">
						<h2>Modulus</h2>
						<span>Data Analysis Framework</span>
						<div class="border_box">
							<p>
								Modulus is a browser-based data analysis framework that provides
								real-time data visualization services. It can be accessed from
								anywhere, allowing users to directly connect to data and
								collaborate by sharing code in real time. With open-source
								modules and processes, from basic analysis to advanced services,
								Modulus enables users to handle all stages of data analysis
								conveniently.
							</p>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="service-subservice">
				<div class="bottom-stroke">
					<div class="subservice-img subservice2">
						<img v-scrollanimation :src="service2" />
						<div class="bg_color">
							<button class="btn--md btn--border-gray">
								<router-link to="/serviceBiMining">자세히 보기</router-link>
							</button>
						</div>
					</div>
					<div class="subservice-text">
						<h2>BI-Mining</h2>
						<span>데이터 시각화 기반 업무용 툴</span>
						<div class="border_box">
							<p>
								실시간 데이터 가시화 서비스를 제공하는 모듈러스는 브라우저 형
								데이터 분석 프레임워크로 어디서나 접속하여 사용 가능하며
								데이터를 직접 보면서 접속한 사용자들 간의 실시간 코드 공유를
								통한 협업이 가능합니다. 소스코드의 모듈화와 프로젝트의 자 동
								연동, 동형 분석 서비스까지 탑재한 모듈러스로 데이터 분석의 모든
								과정을 편리하게 이용할 수 있습니다.
							</p>
						</div>
					</div>
				</div>
			</div> -->
			<div class="service-subservice service-subservice--reverse">
				<div class="bottom-stroke">
					<div class="subservice-img">
						<img v-scrollanimation :src="service3" />
						<div class="bg_color">
							<router-link to="/servicevaccine">
								<button class="btn--md btn--border-gray">View Details</button>
							</router-link>
						</div>
					</div>
					<div class="subservice-text">
						<h2>FC-Anti Virus</h2>
						<div class="border_box">
							<p>
								This antivirus service detects and treats various types of
								malware, including worms, trojans, and ransomware. Utilizing
								machine learning algorithms independently developed by FCTS, it
								not only identifies known malware but also detects and treats
								new and mutated malicious codes.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="service-subservice service-subservice--reverse">
				<div class="bottom-stroke">
					<div class="subservice-img">
						<img v-scrollanimation :src="service4" />
						<div class="bg_color">
							<router-link to="/serviceBiMining">
								<button class="btn--md btn--border-gray">View Details</button>
							</router-link>
						</div>
					</div>
					<div class="subservice-text">
						<h2>BI Mining</h2>
						<span>Business Assistance Tool</span>
						<div class="border_box">
							<p>
								BI Mining is a comprehensive business assistance tool equipped
								with various functions such as data visualization, automatic
								chart creation, report writing, and PPT template provision.
								Users can easily handle complex tasks and efficiently perform
								tasks from Excel modification to PPT creation on a single
								platform. It is a cloud-based service that allows easy access
								anytime and anywhere, enabling automation of business processes.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="service-subservice service-subservice--reverse">
				<div class="bottom-stroke">
					<div class="subservice-img">
						<img v-scrollanimation :src="service5" />
						<div class="bg_color">
							<router-link to="/serviceCryptaLedger">
								<button class="btn--md btn--border-gray">
									View Details
								</button></router-link
							>
						</div>
					</div>
					<div class="subservice-text">
						<h2>CryptaLedger Tech</h2>
						<span>Distributed Ledger Encryption Technology</span>
						<div class="border_box">
							<p>
								CryptaLedger Tech offers an innovative solution that securely
								stores and transmits transaction data on a distributed ledger
								through homomorphic encryption technology. This technology
								thoroughly protects account information and transaction details
								while ensuring the confidentiality and security of the data.
								User data can be securely managed without the need for
								decryption, and robust security is maintained even on Public
								Blockchains. CryptaLedger Tech provides a secure environment for
								users to process and manage their transaction data.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import service1 from '../assets/images/service/service1.jpg';
import service2 from '../assets/images/service/service2.jpg';
import service3 from '../assets/images/service/service3.png';
import service4 from '../assets/images/service/service4.png';
import service5 from '../assets/images/service/service5.png';
import securityImg from '@/assets/images/main/security.svg';
import cryptoImg from '@/assets/images/main/crypto.svg';
import vaccineImg from '@/assets/images/main/vaccine.svg';
import servicemain from '@/assets/images/service/servicemain.svg';
import developer from '@/assets/images/service/developer.svg';
import diagnosis from '@/assets/images/service/Diagnosis.svg';
import product from '@/assets/images/service/product.svg';
import solution from '@/assets/images/service/solution.svg';

export default {
	data: function () {
		return {
			service1,
			service2,
			service3,
			service4,
			service5,
			securityImg,
			cryptoImg,
			vaccineImg,
			servicemain,
			developer,
			diagnosis,
			product,
			solution,
		};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
	},
};
</script>

<style></style>
