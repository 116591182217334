<template>
	<div>
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<bread-crumbs :crumbs="crumbs" @selected="selected"></bread-crumbs>
		<service-content></service-content>
		<router-view></router-view>
		<Footer />
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';
import ServiceContent from '../components/ServiceContent.vue';
import MenuIntro from '@/components/common/MenuIntro.vue';
import BreadCrumbs from '@/components/common/BreadCrumbs.vue';
export default {
	components: { MainHeader, Footer, ServiceContent, MenuIntro, BreadCrumbs },
	data() {
		return {
			// crumbs: [
			// 	{ name: 'Home', link: '/' },
			// 	{ name: '서비스', link: '/service' },
			// ],
			// menuKr: '서비스',
			// menuEn: 'Service',
		};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
		menuKr() {
			return this.computedValue === 'ko' ? '서비스' : '';
		},
		menuEn() {
			return 'Service';
		},
		crumbs() {
			return this.computedValue === 'ko'
				? [
						{ name: 'Home', link: '/' },
						{ name: '서비스', link: '/service' },
				  ]
				: [
						{ name: 'Home', link: '/' },
						{ name: 'Service', link: '/service' },
				  ];
		},
	},
	methods: {
		selected(crumb) {
			this.$emit('selected', crumb);
			this.$router.push(crumb.link);
		},
	},
};
</script>

<style></style>
